.timelines-container {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 34px;
  /* margin: 40px 0; */
}

.timelines-container::after {
  background-color: rgb(216, 216, 240);
  content: "";
  position: absolute;
  left: 4px;
  /* left: calc(50% - 2px); */
  width: 1.8px;
  height: 100%;
}
.timelines-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 98%;
}

.timelines-item {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}
.timelines-item-content {
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); */
  /* border: 0.5px solid #8294a5; */
  border-radius: 10px;
  background-color: #deecff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  position: relative;
  width: 100%;
  /* max-width: 70%; */
  text-align: right;
}

.timelines-item-content::after {
  content: " ";
  /* border: 0.5px solid #00aeef; */
  background-color: #deecff;
  /* box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2); */
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timelines-item:nth-child(odd) .timelines-item-content {
  text-align: left;
  align-items: flex-start;
}

.timelines-item:nth-child(even) .timelines-item-content {
  text-align: left;
  align-items: flex-start;
}

.timelines-item .timelines-item-content::after {
  right: auto;
  left: -7.5px;
  /* box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2); */
}

.timelines-item-content .tag {
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  top: 5px;
  left: 5px;
  letter-spacing: 1px;
  padding: 5px;
  position: absolute;
  text-transform: uppercase;
}

.timelines-item:nth-child(odd) .timelines-item-content .tag {
  left: auto;
  right: 5px;
}

.timelines-item-content time {
  color: #8294a5;
  font-size: 12px;
  font-weight: bold;
}

.timelines-item-content p {
  font-size: 14px;
  line-height: 24px;
  margin: 5px 0;
  width: 100%;
}

.timelines-item-content a {
  font-size: 14px;
  font-weight: bold;
}

.timelines-item-content a::after {
  content: " ►";
  font-size: 12px;
}

.timelines-item-content .circle {
  background-color: #00838c;
  border: 3px solid #00838c;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 5px);
  right: -40px;
  width: 10px;
  height: 10px;
  z-index: 100;
}

.timelines-item .timelines-item-content .circle {
  right: auto;
  left: -30px;
}

/* .timeline-item:nth-child(even) .timeline-item-content .circle {
    right: auto;
    left: -35px;
} */

@media only screen and (max-width: 1023px) {
  .timelines-item-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timelines-item-content,
  .timelines-item .timelines-item-content {
    /* padding: 15px 10px; */
    text-align: center;
    align-items: center;
  }

  .timelines-item-content .tag {
    width: calc(100% - 10px);
    text-align: center;
  }

  .timelines-item-content time {
    /* margin-top: 20px; */
  }

  .timelines-item-content a {
    text-decoration: underline;
  }

  .timelines-item-content a::after {
    display: none;
  }
}
